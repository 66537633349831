const $ = require(`jquery`)
window.jQuery = $

/*
const addScript = url => {
  const script = document.createElement("script")
  script.src = url
  script.async = true
  document.body.appendChild(script)
}

exports.onClientEntry = () => {
  window.onload = () => {
    
  }
}
*/

exports.onRouteUpdate = ({ location, prevLocation }) => {  
  $(window).bind("load", function() {   
    $(".sidebar a.dropdown-toggle").click(function() {
      if ($(this).hasClass('active')) {
        $(this).removeClass('active');
      }
      else {
        $(this).addClass('active');
      }
    });
  });

  if ($('form[name="demo"]').length) {
    var source = getQueryParameterValues('utm_source'); 
    if(source === "amazon_workspaces") {
      $("#source").val("Amazon Workspace");
    }
  }
}

function getQueryParameterValues(param) {  
  var url = window.location.href.slice(window.location.href.indexOf('?') + 1).split('&');  
  for (var i = 0; i < url.length; i++) {  
      var urlparam = url[i].split('=');  
      if (urlparam[0] === param) {  
          return urlparam[1];  
      }  
  }  
} 