// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-amazonworkspaces-js": () => import("./../../../src/pages/amazonworkspaces.js" /* webpackChunkName: "component---src-pages-amazonworkspaces-js" */),
  "component---src-pages-careers-index-js": () => import("./../../../src/pages/careers/index.js" /* webpackChunkName: "component---src-pages-careers-index-js" */),
  "component---src-pages-careers-senior-software-engineer-js": () => import("./../../../src/pages/careers/senior-software-engineer.js" /* webpackChunkName: "component---src-pages-careers-senior-software-engineer-js" */),
  "component---src-pages-company-index-js": () => import("./../../../src/pages/company/index.js" /* webpackChunkName: "component---src-pages-company-index-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-platform-js": () => import("./../../../src/pages/platform.js" /* webpackChunkName: "component---src-pages-platform-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-request-demo-js": () => import("./../../../src/pages/request-demo.js" /* webpackChunkName: "component---src-pages-request-demo-js" */),
  "component---src-pages-success-js": () => import("./../../../src/pages/success.js" /* webpackChunkName: "component---src-pages-success-js" */),
  "component---src-pages-terms-customer-support-services-policy-js": () => import("./../../../src/pages/terms/customer-support-services-policy.js" /* webpackChunkName: "component---src-pages-terms-customer-support-services-policy-js" */),
  "component---src-pages-terms-gdpr-js": () => import("./../../../src/pages/terms/gdpr.js" /* webpackChunkName: "component---src-pages-terms-gdpr-js" */),
  "component---src-pages-terms-index-js": () => import("./../../../src/pages/terms/index.js" /* webpackChunkName: "component---src-pages-terms-index-js" */),
  "component---src-pages-terms-privacy-shield-privacy-policy-js": () => import("./../../../src/pages/terms/privacy-shield-privacy-policy.js" /* webpackChunkName: "component---src-pages-terms-privacy-shield-privacy-policy-js" */),
  "component---src-pages-terms-security-framework-js": () => import("./../../../src/pages/terms/security-framework.js" /* webpackChunkName: "component---src-pages-terms-security-framework-js" */),
  "component---src-pages-terms-service-level-standards-js": () => import("./../../../src/pages/terms/service-level-standards.js" /* webpackChunkName: "component---src-pages-terms-service-level-standards-js" */),
  "component---src-pages-terms-subprocessors-js": () => import("./../../../src/pages/terms/subprocessors.js" /* webpackChunkName: "component---src-pages-terms-subprocessors-js" */),
  "component---src-pages-terms-terms-and-conditions-js": () => import("./../../../src/pages/terms/terms-and-conditions.js" /* webpackChunkName: "component---src-pages-terms-terms-and-conditions-js" */),
  "component---src-pages-terms-website-terms-of-use-js": () => import("./../../../src/pages/terms/website-terms-of-use.js" /* webpackChunkName: "component---src-pages-terms-website-terms-of-use-js" */),
  "component---src-templates-docs-js": () => import("./../../../src/templates/docs.js" /* webpackChunkName: "component---src-templates-docs-js" */)
}

